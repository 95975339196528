import { usePrpStore } from '../contexts/prpContext'

const useProductResultPage = (selector) => {
  // pass undefined to trigger warnings if selector is not passed
  const innerSelector = selector
    ? (state) => selector(state.state)
    : (x) => x.state
  const storeValue = usePrpStore(innerSelector)
  if (!selector) {
    console.warn(
      'useProductResultPage called without a selector, this is not recommended as it will cause the component to re-render on any state change',
    )
  }
  return storeValue
}

export default useProductResultPage
